<template>
  <div>
    <div class="main">
      <div class="head">
        素材详情
      </div>
      <el-table
        :data="tableData"
        border
        style="width: 100%;background: white">

        <el-table-column
          prop="industry_name"
          label="行业名称">
        </el-table-column>

        <el-table-column
          prop="type_title"
          label="竞品类型">
        </el-table-column>

        <el-table-column
          label="素材">
          <template slot-scope="scope">
            <el-image
              style="width: 80px; height: 50px;"
              fit="contain"
              :src="scope.row.type == 0 ? scope.row.visit_path : scope.row.screen_shot"
              @click="dialogShow(scope.row)"/>
          </template>
        </el-table-column>

        <el-table-column
          prop="remark"
          label="备注">
        </el-table-column>

        <el-table-column
          prop="create_time"
          label="创建时间"
          width="250px">
        </el-table-column>

      </el-table>
    </div>
    <index v-if="dialogVisible" :dialogPath="dialogPath" :type="type" :dialogVisible="dialogVisible" @handleClose="handleClose"/>
  </div>
</template>

<script>
  import index from '@/components/dialog/index'
  import { getList } from '@/api/lists'

  export default {
    components: { index },
    data () {
      return {
        dialogVisible: false,
        tableData: [],
        dialogPath: '',
        type: '',
      }
    },
    watch: {
      $route(val) {
        this.initData()
      }
    },
    created() {
      this.initData()
    },
    methods: {
      initData() {
        let key = { path: 'compete', key: this.$route.query.shareID }
        getList(key).then( response=> {
          this.tableData = response.data.data
        })
      },
      dialogShow(row) {
        this.dialogPath = row.visit_path
        this.type = row.type
        this.dialogVisible = true
      },
      handleClose() {
        this.dialogPath = ''
        this.dialogVisible = false
      }
    }
  }
</script>

<style scoped>
  .main {
    width: 95%;
    margin: 0 auto;
    box-shadow: 0 0 5px rgba(224, 224, 224, 0.6);
    padding: 12px 20px;
    background: white;
  }
  .head {
    width: 100%;
    height: 40px;
    /*padding-left: 20px;*/
    line-height: 40px;
    font-weight: 700;
    color: #b4b4b4;
    /*text-shadow: 0.4px 1px 2px #A5A5A5;*/
    overflow: hidden;
  }
</style>
